import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  connect() {
    this.toggleVocRegion(this.data.has('role') && ['3', '4', '5', '6', '7'].includes(this.data.get('role')))
    this.toggleReadOnly(this.data.has('role') && ['2', '3'].includes(this.data.get('role')))
    this.toggleOrg(this.data.has('role') && ['4'].includes(this.data.get('role')))
    this.toggleOrgLabel(this.data.has('role') && ['3', '5', '6', '7'].includes(this.data.get('role')))
    this.toggleOrgWork(this.data.has('role') && ['3', '4'].includes(this.data.get('role')))
    this.togglePublicOrgWork(this.data.has('role') && ['5', '6', '7'].includes(this.data.get('role')))
    StimulusReflex.register(this)
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleVocRegion(
      ['3', '4', '5', '6', '7'].includes(roleId)
    )
    this.toggleOrg(
      ['4'].includes(roleId)
    )

    this.toggleReadOnly(
      ['2', '3'].includes(roleId)
    )

    this.toggleOrgLabel(['3', '5', '6', '7'].includes(roleId))
    this.toggleOrgWork(['3', '4'].includes(roleId))
    this.togglePublicOrgWork(['5', '6', '7'].includes(roleId))
  }

  toggleVocRegion(isReg) {
    if (isReg) {
      $('#voc_region').show()
    } else {
      $('#voc_region').hide()
    }
  }

  toggleOrg(isReg) {
    if (isReg) {
      $('#organization').show()
    } else {
      $('#organization').hide()
    }
  }

  toggleOrgLabel(isOrgLabel) {
    if (isOrgLabel) {
      $('#org_label').show()
    } else {
      $('#org_label').hide()
    }
  }

  toggleOrgWork(isOrgWork) {
    if (isOrgWork) {
      $('#org_department').show()
      $('#org_position').show()
    } else {
      $('#org_department').hide()
      $('#org_position').hide()
    }
  }
  togglePublicOrgWork(isOrgWork) {
    if (isOrgWork) {
      $('#public_org').show()
      $('#org_position').show()
    } else {
      $('#public_org').hide()
      $('#org_position').hide()
    }
  }

  toggleReadOnly(isReadOnly) {
    if (isReadOnly) {
      $('#read_only_checkbox').show()
    } else {
      $('#read_only_checkbox').hide()
    }
  }

  loadOrganizations(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#user-organizations-details select').attr('data-controller', '')

    this.stimulate('User#organizations_details', { serializeForm: false }, id)
  }
}
