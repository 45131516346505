import { Controller } from "stimulus"
import $ from "jquery"
import 'bootstrap/js/dist/popover'

export default class extends Controller {
  connect() {
    $(this.element).popover()
  }

  disconnect() {
    $(this.element).popover('dispose')
  }
}
