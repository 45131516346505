import { Controller } from "stimulus"
import { fire, enableElement } from "@rails/ujs"

export default class extends Controller {

  static targets = [ "condition", "reason" ]

  confirm(event) {
    if (!(this.reasonTarget.value === 'merged' || this.reasonTarget.value === 'merged_filial')) {
      return
    }

    let conditionsInParent = JSON.parse(this.element.dataset.closeParentNokConditions)
    let orgConditions = this.conditionTargets.filter(x => x.checked === true).map(x => x.value)

    let extraConditions = orgConditions.filter(x => !conditionsInParent.includes(parseInt(x)))

    let check = true

    if (extraConditions.length > 0) {
      check = confirm('Вы уверены, что хотите произвести слияние этих организаций? У выбранной Вами головной организации меньше условий для НОК, чем у организации, требующей слияние. В случае слияния организаций анкеты по невыбранному(ым) условию(ям) для НОК в головной организации будут потеряны.')
    }

    if (!check) {
      setTimeout(function() {
        fire(event.target, 'ujs:enable')
        enableElement(event.target)
      }, 50)
      event.preventDefault()
    }
  }

  selectParent(event) {
    this.element.dataset.closeParentNokConditions = JSON.stringify(event.detail.event.params.data.nok_cnt)
  }
}
