import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "to", "fields" ]

  connect() {
    this.processPeriod(this.toTarget.value)
  }

  doChangeTo(event) {
    this.processPeriod(this.toTarget.value)
  }

  processPeriod(v) {
    if (this.hasFieldsTarget) {
      if (v) {
        this.fieldsTarget.classList.remove('d-none')
      } else {
        this.fieldsTarget.classList.add('d-none')
      }
    }
  }
}
