import { Controller } from "stimulus"
import * as echarts from 'echarts';

import { formatNumber } from 'number-format';

import $ from "jquery"

export default class extends Controller {
  connect() {

    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let title = this.data.get('title')
    let stacked = this.data.get('stacked')
    let trendline = this.data.get('trendline')
    let yType = this.data.get('yType') || 'value'
    let datasetLabels = JSON.parse(this.data.get('datasetLabels')) || []

    let min = yType == 'log' ? 1 : undefined

    let datasets = []

    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'bar',
        data: el,
        tooltip: {
          valueFormatter: formatNumber
        },
      }

      datasets.push(ds)
    })

    this.chart = echarts.init(this.element)

    let opts = {
      legend: {},
      title: {
        text: title
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        data: labels,
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          rotate: 30
        },
      },
      yAxis: {
        type: yType,
        min: min,
        axisLabel: {
          formatter: formatNumber
        },
        axisPointer: {
          label: {
            formatter: formatNumber
          }
        }
      },
      series: datasets,
      dataZoom: [
        {
          type: 'inside'
        },
        {
          type: 'slider'
        }
      ],
    }

    this.chart.setOption(opts)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
