import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  loadOrganizations(event) {
    let id = $(event.target).val()
    if (!id) { return }

    $('#registration-user-organizations-details select').attr('data-controller', '')

    this.stimulate('Registration::User#organizations_details', { serializeForm: false }, id)
  }
}
