import { Controller } from "stimulus"

import * as echarts from 'echarts';

import { formatNumber } from 'number-format';

import regression from 'regression';
import { round, determinationCoefficient } from 'math-utils';


export default class extends Controller {
  connect() {
    let labels = JSON.parse(this.data.get('labels')) || []
    let colors = JSON.parse(this.data.get('colors')) || []
    let data = JSON.parse(this.data.get('data')) || []
    let datasetLabels = JSON.parse(this.data.get('datasetLabels')) || []

    let title = this.data.get('title')
    let type = this.data.get('type')
    let trendline = this.data.get('trendline')
    let suggestedMax = this.data.get('suggestedMax')
    let suggestedMin = this.data.get('suggestedMin')
    let max = this.data.get('max')
    let min = this.data.get('min')
    let precision = this.data.get('precision')
    let regrType = this.data.get('trendlineRegression') || 'linear'

    let datasets = []

    /*data.forEach((el, ix) => {
      let ds = {
        label: datasetLabels[ix],
        data: el,
        borderColor: colors[ix],
        backgroundColor: colors[ix],
        fill: false,
        tension: 0.1,
      }

      datasets.push(ds)

      if (trendline) {
        let regrData = []
        for (let i = 0; i < el.length; i++) {
          regrData.push([i, el[i] || 0])
        }
        let regr = regression[regrType](regrData)
        let calcRegrData = [];
        for (let i = 0; i < el.length; i++) { // Fill the array with calculated values
          calcRegrData.push(regr.points[i][1]);
        }

        datasets.push({
          label: regr.r2 ? `${datasetLabels[ix]} - тренд (R2 = ${regr.r2})` : `${datasetLabels[ix]} - тренд (R2 = ${round(determinationCoefficient(regrData, regr.points), 2)})`,
          borderColor: 'rgba(0,0,0)',
          backgroundColor: 'rgba(0,0,0)',
          type: 'line',
          tension: 0.1,
          data: calcRegrData,
          fill: false,
          borderWidth: 1,
          pointRadius: 0
        })
      }
    })*/

    data.forEach((el, ix) => {
      let ds = {
        name: datasetLabels[ix],
        type: 'line',
        data: el,
        tooltip: {
          valueFormatter: formatNumber
        },
      }

      datasets.push(ds)

      if (trendline) {
        let regrData = []
        for (let i = 0; i < el.length; i++) {
          regrData.push([i, el[i] || 0])
        }
        let regr = regression[regrType](regrData)
        let calcRegrData = [];
        for (let i = 0; i < el.length; i++) { // Fill the array with calculated values
          calcRegrData.push(regr.points[i][1]);
        }

        datasets.push({
          name: regr.r2 ? `${datasetLabels[ix]} - тренд (R2 = ${regr.r2})` : `${datasetLabels[ix]} - тренд (R2 = ${round(determinationCoefficient(regrData, regr.points), 2)})`,
          type: 'line',
          data: calcRegrData,
          tooltip: {
            valueFormatter: formatNumber
          },
          color: '#000',
          lineStyle: {
            width: 1,
          },
          showSymbol: false,
        })
      }
    })

    this.chart = echarts.init(this.element)

    let opts = {
      animation: false,
      legend: {},
      title: {
        text: title
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        data: labels,
        type: 'category',
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        axisLabel: {
          formatter: formatNumber
        },
        axisPointer: {
          label: {
            formatter: formatNumber
          }
        }
      },
      series: datasets,
      dataZoom: [
        {
          type: 'inside'
        },
        {
          type: 'slider'
        }
      ],
    }

    this.chart.setOption(opts)
  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
