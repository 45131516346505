import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "reason", "fields" ]

  connect() {
    this.processReason(this.reasonTarget.value)
  }

  doChangeReason(event) {
    this.processReason(event.target.value)
  }

  processReason(sub) {
    if (sub === 'closed') {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.add('d-none')
      }
    } else if (sub === 'merged' || sub === 'merged_filial') {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.add('d-none')
      }
    }
  }
}
