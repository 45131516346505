import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "reasonCell" ]
  doCheckIsInPeriod(event) {
    if (event.target.checked) {
      if (this.hasReasonCellTarget) {
        this.reasonCellTarget.classList.add('d-none')
      }
    } else {
      if (this.hasReasonCellTarget) {
        this.reasonCellTarget.classList.remove('d-none')
      }
    }
  }
}
