import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "branch", "fields" ]

  connect() {
    this.processBranch(this.branchTarget.value)
  }

  doChangeBranch(event) {
    this.processBranch(event.target.value)
  }

  processBranch(sub) {
    if (sub === 'head') {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.add('d-none')
      }
    } else if (sub === 'branch') {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.add('d-none')
      }
    }
  }
}
