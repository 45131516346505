import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
    this.setEnabledQuestions()
  }
  loadOrganizations(event) {
    let id = $(event.target).val()
    let idNok = $(event.target).data('voc-nok-condition-id')

    if (!id) { return }

    $('#anketa-result-organizations-details select').attr('data-controller', '')

    this.stimulate('Anketa::Result#organizations_details', { serializeForm: false }, id, idNok)
  }

  setEnabledQuestions(event) {
    $('input[data-dependent-questions]').each(function(ix, el) {
      let $el = $(el)
      $.each($el.data('dependent-questions'), function(ix2, dep_q_id) {
        let $el_dep = $('[data-question-id=' + dep_q_id + ']')

        $el_dep.prop('disabled', true)
      })
    })

    $('input[data-dependent-questions]:checked').each(function(ix, el) {
      let $el = $(el)

      $.each($el.data('dependent-questions'), function(ix2, dep_q_id) {
        let $el_dep = $('[data-question-id=' + dep_q_id + ']')

        if (!$el.is(':disabled')) {
          $el_dep.prop('disabled', false)
        }
      })
    })
  }
}
