import { Controller } from "stimulus"
import $ from "jquery"
//import templateFn from "mz_ui/utils/template"
//import renderPartialMixin from "mz_ui/utils/render_partial_mixin"
import { fire } from '@rails/ujs'

export default class extends Controller {
  static targets = [/*"newItem",*/ "tableBody", "form", "editButton", "saveButton", "cancelButton"]

  /*initialize () {
    Object.assign(this, renderPartialMixin)
  }*/

  connect() {
    //let updatesController = this;

    //updatesController.renderPartial(updatesController.element, data['body']);

    this.cnt = 0
  }

  // addNewItem(e) {
  //   this.startEdit()
  //   const t = templateFn($(this.newItemTarget).html())
  //   this.cnt += 1
  //   $(this.tableBodyTarget).prepend(t({id: 'new-' + this.cnt}))
  //   e.preventDefault()
  // }

  saveItems(e) {
    fire(this.formTarget, 'submit')
    e.preventDefault()
  }

  doReplaceRows(e) {
    const $content = $(e.detail[2].response);
    const $tableBody = $(this.tableBodyTarget);

    $content.find('tr[data-public-council-period-region-organization-record-id]').each(function(ix, el) {
      const recId = $(el).attr('data-public-council-period-region-organization-record-id')
      $tableBody.find(`tr[data-public-council-period-region-organization-record-id="${recId}"]`).html($(el).html())
    })
  }

  startEdit() {
    $(this.editButtonTarget).addClass('d-none')
    $(this.saveButtonTarget).removeClass('d-none')
    $(this.cancelButtonTarget).removeClass('d-none')
  }

  cancelEdit(e) {
    $(this.editButtonTarget).removeClass('d-none')
    $(this.saveButtonTarget).addClass('d-none')
    $(this.cancelButtonTarget).addClass('d-none')

    if (e) {
      e.preventDefault()
    }
  }
}
