import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "federal", "fields" ]

  connect() {
    this.processFederal(this.federalTarget.value)
  }

  doChangeFederal(event) {
    this.processFederal(event.target.value)
  }

  processFederal(sub) {
    if (sub === '1') {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasFieldsTarget) {
        this.fieldsTarget.classList.add('d-none')
      }
    }
  }
}
