import { Controller } from "stimulus"
import $  from "jquery"
import { serializeElement } from "@rails/ujs"
import { activateLink } from "mz_ui/utils/activate_link"
import template from "mz_ui/utils/template"
import { merge } from "mz_ui/utils/helpers"
import { inputHasValue, serializeFilter, removeFilterFromQuery } from "mz_ui/utils/filters"

let getFormTarget = getFormTarget = function(c) { if (c.hasFormTarget) { return c.formTarget; } else { return c.element; } };
let getSerializedFilters = function(c) {
  let out = {};
  c.inputTargets.forEach(el => out = merge(out, serializeFilter(el, true)));
  return out;
};

let onlyUnique = function (value, index, self) {
  return self.indexOf(value) === index;
}

export default class extends Controller {
  static targets = ['form', 'filter', 'input', 'panel', 'filterCnt', 'tagPanel', 'tagTemplate', 'submit']

  connect() {
    if (getFormTarget(this).getAttribute('data-search-form-column-filters') && this.inputTargets.some(el => inputHasValue(el))) {
      $(this.filterTargets).addClass('visible');
    }

    const len = this.inputTargets.filter(el => inputHasValue(el)).length;

    if (this.hasFilterCntTarget && (len > 0)) {
      $(this.filterCntTarget).removeClass('d-none').html(len);
    }

    if (this.hasTagPanelTarget && this.hasTagTemplateTarget) {
      if (len > 0) { $(this.tagPanelTarget).removeClass('d-none'); }
      const t = template(this.tagTemplateTarget.innerHTML);
      const $panel = $(this.tagPanelTarget).empty(); // clear panel first (fix: doubles on back action)
      const filters = getSerializedFilters(this);
      return (() => {
        const result = [];
        for (let k in filters) {
          const v = filters[k];
          v.value_formatted = v.value.join(', ');
          result.push($panel.append($(t(v))));
        }
        return result;
      })();
    }
  }

  doSearch(event) {
    if (event != null) { event.preventDefault(); }

    let sf = getFormTarget(this)

    if (this.hasSubmitTarget) {
      this.submitTarget.click()
    } else if (sf.getAttribute('data-search-form-submit')) {
      sf.submit()
    } else {
      const url = this.getUrl()
      activateLink(url, this.element, true)
    }
  }

  doRemoveFilter(event) {
    const f = event.currentTarget.getAttribute('data-search-form-filter');
    const url = this.getUrl([f]);
    return activateLink(url, this.element, true);
  }

  getUrl(skipFilterNames) {
    const form = getFormTarget(this);
    let url = form.getAttribute('action');
    let data = '';

    [form].concat(this.inputTargets).forEach(function(el) {
      if (data.length > 0) { data += '&'; }
      return data += serializeElement(el);
    });

    if (skipFilterNames != null) {
      for (let f of Array.from(skipFilterNames)) {
        data = removeFilterFromQuery(data, f);
      }
    }

    data = data.split('&').filter(onlyUnique).join('&')

    if (url.indexOf('?') < 0) {
      url += '?' + data;
    } else {
      url += '&' + data;
    }

    return url;
  }

  doReset(event) {
    if (event != null) { event.preventDefault(); }
    const form = getFormTarget(this);
    const url = form.getAttribute('action');
    return activateLink(url, this.element, true);
  }

  togglePanelFilters() {
    return $(this.panelTarget).fadeToggle('fast');
  }
}
