import { Controller } from "stimulus"
import $ from "jquery"
import { stopEverything } from "@rails/ujs"

export default class extends Controller {
  static targets = [ 'modal' ]

  open(event) {
    $(this.modalTarget).modal('show');

    stopEverything(event);
  }

  close() {
    $(this.modalTarget).modal('hide');
  }
}
